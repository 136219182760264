import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee } from '@fortawesome/free-solid-svg-icons'; //see https://fontawesome.com/how-to-use/on-the-web/using-with/react
import { graphql, Link } from 'gatsby';
import Layout from '../components/layout';
import Hero from '../components/hero';
import Section from '../components/section';
// import { StaticImage } from 'gatsby-plugin-image';
import { GatsbyImage, getImage, IGatsbyImageData } from "gatsby-plugin-image"

function Person({ person }){
  //Images are not downloaded by default when using the Contentful integration, serving the images directly from contentful CDN instead.
  //We can turn on image download + pass images through the Sharp plugin, but this would be heavier on build times, etc.
  //Docs: https://www.gatsbyjs.com/plugins/gatsby-source-contentful/#download-assets-for-static-distribution

  console.log(`person`, person);
  const image = getImage(person.headshot) as IGatsbyImageData;
  return <div>
    <GatsbyImage image={image} alt={person.headshot.title} style={{borderRadius:'50%'}}/>
    <h3>{person.name}</h3>
    <div>{person.jobTitle}</div>
    <div><a href={person.linkedin}>Connect</a></div>
    <div
      dangerouslySetInnerHTML={{
        __html: person.bio.childMarkdownRemark.html,
      }}
    />
  </div>;
}

export default function Index({ data }) {
  // const [date, setDate] = useState<any>(undefined);
  // useEffect(() => {
  //   async function getDate() {
  //     const res = await fetch('/api/date');
  //     const newDate = await res.text();
  //     setDate(newDate);
  //   }
  //   getDate();
  // }, []);

  // console.log(`data`, data); //This data automagically comes in from the GraphQL query defined below
  const delivery = data.delivery.nodes;
  return <Layout>
    <Helmet>
      <title>Cro Metrics</title>
    </Helmet>
    <Hero>
      <h1>Accelerate Your Growth Through Strategic, Data-Driven Experimentation</h1>
      <a href="https://crometrics.com/contact/" className="btn">CONTACT US</a>
    </Hero>
    <div className="container">
      <h2>Gatsby Test Landing Page</h2>
      <FontAwesomeIcon icon={faCoffee}/> Font Awesome Works! <br />
      <Link to="/page2">Link to page 2</Link>
    </div>
    <Section id="something">
      <h2>A Proven Growth Cycle</h2>
      <div>
        <img src="https://crometrics.com/wp-content/uploads/2020/01/growth_cycle-desktop.svg" alt="A Proven Growth Cycle"/>
      </div>
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Officia amet cupiditate quas, fugiat quo reiciendis asperiores quisquam quae, illum dolorem, ipsam veniam velit. Fugiat magnam sunt sed natus similique repellat!
      </p>
    </Section>
    <Section id="meet-the-team">
      <h2>Delivery</h2>
      <ul>
        { delivery.map((node, i)=><li key={i}><Person person={node} /></li>) }
      </ul>
    </Section>
  </Layout>;
};

export const query = graphql`
  query {
    site {
      siteMetadata {
        name
      }
    }
    delivery: allContentfulTeamMembers(
      filter: {group: {eq: "Delivery Team"}}
      sort: {fields: name, order: ASC}
    ) {
      nodes {
        ...croTeam
      }
    }
  }

  fragment croTeam on ContentfulTeamMembers {
    name
    jobTitle
    linkedin
    group
    bio {
      childMarkdownRemark {
        html
      }
    }
    headshot {
      gatsbyImageData(width: 170, height: 170, formats: WEBP)
      title
    }
  }

`;